import { withSxProp } from "@/utils"
import { FormControl, InputLabel, MenuItem, Select, SelectProps, SxProps, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { defaultSx, menuSx } from "./styles"

interface BaseSelectProps {
    /**
     * menu id
     */
    id?: string
    label: string
    /**
     * MUI style with select as root
     */
    sx?: SxProps
    /**
     * Default selected
     */
    defaultValue?: string
    /**
     * html classes
     */
    className?: string
    /**
     * Tell if select is required
     */
    required?: boolean
    /**
     * Options list
     */
    options: { value: string; label: string; prefix?: any }[]
    /**
     * disabled
     */
    disabled?: boolean
    /**
     * register
     */
    register?: any
    /**
     * data-test-id
     */
    dataTestId?: string
    onItemClicked?: (item: any) => void
    formControlSx?: SxProps
    value?: string
}

const BaseSelect = ({
    id,
    label,
    sx,
    defaultValue,
    className,
    required = false,
    options,
    disabled,
    register,
    onItemClicked,
    dataTestId,
    formControlSx,
    value,
    ...rest
}: BaseSelectProps & SelectProps) => {
    const { t } = useTranslation()

    return (
        <FormControl
            sx={formControlSx}
            fullWidth
            data-testid={dataTestId}
            className={className}
            variant="filled"
            required={required}
            {...rest}
            {...register}
            disabled={disabled}
        >
            <InputLabel
                sx={{
                    "&, &.Mui-focused": {
                        color: "action.active",
                    },
                    "& .MuiFormLabel-asterisk": {
                        color: "primary.main",
                    },
                }}
                id={id}
            >
                {label}
            </InputLabel>
            <Select
                defaultValue={defaultValue}
                value={value}
                labelId={id}
                MenuProps={{
                    className: "select-menu-wrapper",
                    classes: { paper: "select-paper" },
                }}
                sx={withSxProp(defaultSx, sx)}
            >
                {(!options || options?.length === 0) && (
                    <MenuItem value="no-options">
                        <Typography>{t("AUTOCOMPLETE.NO_OPTIONS")}</Typography>
                    </MenuItem>
                )}
                {options?.map((option, key) => (
                    <MenuItem
                        value={option.value}
                        key={key}
                        sx={menuSx}
                        onClick={() => {
                            if (typeof onItemClicked === "function") {
                                onItemClicked(option)
                            }
                        }}
                    >
                        {option.prefix && <Typography component={"span"}>{option.prefix}</Typography>}
                        <span>{option.label}</span>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default BaseSelect
