import { LocationApi } from "@/api"
import { CreateGMBAccessType } from "@/api/location"
import env from "@/config/env"
import { socket } from "@/config/socket"
import GoogleStore from "@/store/google"
import Cookies from "js-cookie"
import { useCallback, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import useUtilsService from "../useBase64"

const useLogic = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const { decode64AndParse } = useUtilsService()
    const { callbackUrl, locationName } = GoogleStore()

    const createGmbAccess = useCallback(async (data: CreateGMBAccessType) => {
        const resp = await LocationApi.createGmbAccess({
            companyId: data.companyId,
            redirecturi: data.redirecturi,
            gmbAccessId: data.gmbAccessId,
            code: data.code,
        })
        socket.emit("connectLocation", {
            companyId: data.companyId,
            gmbId: data.gmbAccessId,
            userId: data.user_id,
        })

        socket.on("locationStatus", (res) => {
            if (res.nbPassed === res.totalCount) {
                window.location.href = data.callbackUrl + "/?auth_type=google&updated=" + data.locationName
            } else {
                window.location.href = data.callbackUrl + "/?auth_type=google&failed=" + data.locationName
            }
        })
        setTimeout(async () => {
            await LocationApi.synchronize({
                company_id: data.companyId,
                gmb_access_id: resp.gmbAccessId,
                locations_name: [data.locationName],
                user_id: data.user_id,
                account_id: "",
            })
        }, 300)
    }, [])

    useEffect(() => {
        const code = searchParams.get("code") || ""
        const state = searchParams.get("state") || ""
        let inf = null
        if (state) {
            inf = decode64AndParse(state)
            Cookies.set("google_metadata", JSON.stringify(inf))

            createGmbAccess({
                companyId: inf.companyId || "",
                user_id: inf.user_id || "",
                callbackUrl: inf.cb || "",
                gmbAccessId: inf.gmb_access_id || "",
                locationName: inf.locationName || "",
                code,
                redirecturi: env.APP_OAUTH_REDIRECT_URL,
            })
        }

        for (const key of searchParams.keys()) {
            searchParams.delete(key)
        }
        setSearchParams(searchParams)
    }, [searchParams])

    return {
        locationName,
    }
}

export default useLogic
