import Cookies from "js-cookie"
import { create } from "zustand"

export interface IResellerStore {
    resellerLogo: string
    resellerName: string
    resellerFavicon: string
    resellerUid: string
    resellerFetched: boolean
    callbackUrl: string
    clientId: string
}

export interface IResellerSetter {
    updateState: (update: Partial<IResellerStore>) => void
}

const reseller_infos = Cookies.get("reseller_infos") ? JSON.parse(Cookies.get("reseller_infos")) : {}

const initalState = {
    resellerLogo: reseller_infos?.logo || "",
    resellerName: reseller_infos?.name || "",
    resellerFavicon: reseller_infos?.favicon || "",
    resellerUid: Cookies.get("resellerUid"),
    resellerFetched: false,
    callbackUrl: Cookies.get("callbackUrl") || "",
    clientId: Cookies.get("clientId") || "",
}

const ResellerStore = create<IResellerStore & IResellerSetter>((set) => ({
    ...initalState,
    updateState: (update) => set(() => ({ ...update })),
}))

export default ResellerStore
