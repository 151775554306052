import { FacebookApi } from "@/api"
import useFormater from "@/hooks/useFormater"
import useNotification from "@/hooks/useNotification"
import { ResellerService } from "@/services"
import FacebookStore from "@/store/facebook"
import { removeHashUrl } from "@/utils"
import Cookies from "js-cookie"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import useUtilsService from "../useBase64"
import useSelectAccount from "../useSelectAccount"
const useLogic = () => {
    const { hash } = useLocation()
    const navigate = useNavigate()
    const { pages, update } = FacebookStore()
    const [isLoading, setIsLoading] = useState(false)
    const [isFetching, setIsFetching] = useState(false)
    const [selectedPage, setSelectedPage] = useState(null)
    const { queryStringToObject } = useFormater()
    const { decode64AndParse } = useUtilsService()
    const { notif } = useNotification()
    const { t } = useTranslation()
    const { openFacebookAccount } = useSelectAccount()

    const params = useMemo(() => {
        if (hash) {
            const data = queryStringToObject(hash.replace("#", ""))
            let state = null
            if (data?.state) {
                state = decode64AndParse(data?.state)
                data["state"] = state
            }
            return data
        }
        if (Cookies.get("facebook_metadata")) {
            return JSON.parse(Cookies.get("facebook_metadata"))
        }
        return null
    }, [hash])

    const checkFacebookAccess = useCallback(async () => {
        setIsFetching(true)
        if (params) {
            if (params?.access_token) {
                await FacebookApi.createFacebookAccess({
                    company_id: params?.state?.companyId,
                    access_token: params?.access_token,
                    expires_in: params?.expires_in,
                    location_id: params?.state?.location_id,
                })
            }

            const resFetchPages = await FacebookApi.fetchPages({
                company_id: params?.state?.companyId,
                location_id: params?.state?.location_id,
            })
            if (resFetchPages?.length > 0) {
                update({
                    pages: resFetchPages,
                })
                if (window.location.hash) {
                    removeHashUrl()
                }
            }

            if (resFetchPages?.error) {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
        }
        setIsFetching(false)
    }, [params])

    useEffect(() => {
        if (!params && !Cookies.get("facebook_metadata")) {
            navigate("/error")
            return
        }

        const cookies = { ...params }
        delete cookies?.access_token
        delete cookies["22data_access_expiration_time"]
        delete cookies?.expires_in
        delete cookies?.long_lived_token
        Cookies.set("facebook_metadata", JSON.stringify(cookies))
    }, [params])

    useEffect(() => {
        checkFacebookAccess()
    }, [checkFacebookAccess])

    const handleReturn = (status = "") => {
        const metadata = Cookies.get("facebook_metadata") ? JSON.parse(Cookies.get("facebook_metadata")) : null
        if (metadata) {
            Cookies.remove("facebook_metadata")
            Cookies.remove("google_metadata")
            ResellerService.clearReseller()
            window.location.href = `${metadata?.state?.cb}${
                status === "success" ? "/?auth_type=facebook&status=success" : ""
            }`
        }
    }

    const handleCancel = () => {
        handleReturn()
    }

    const handlePrevious = useCallback(() => {
        if (params && params.state) {
            openFacebookAccount({
                companyId: params?.state?.companyId,
                user_id: params?.state?.user_id,
                location_id: params?.state?.location_id,
                cb: params?.state?.cb,
            })
        }
    }, [params])

    const handleSync = useCallback(async () => {
        setIsLoading(true)
        const res = await FacebookApi.synchronize({
            company_id: params?.state?.companyId,
            location_id: params?.state?.location_id,
            pageId: selectedPage,
        })
        setIsLoading(false)
        if (res?.id) {
            handleReturn("success")
        } else if (res?.error) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        }
    }, [selectedPage])

    return {
        params,
        pages,
        isLoading,
        isFetching,
        selectedPage,
        handleCancel,
        handleSync,
        setSelectedPage,
        handlePrevious,
    }
}

export default useLogic
