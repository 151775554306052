// import { CountryCode, isValidNumberForRegion, parsePhoneNumber } from "libphonenumber-js"
import { useTranslation } from "react-i18next"

interface AddressParser {
    region: string
    countryCode: string
    address: string
    city: string
    zip: string
}

const useFormater = () => {
    const { t } = useTranslation()
    const parseAddress = ({ region, countryCode, address, city, zip }: AddressParser): string => {
        let fullAddress = ""
        if (countryCode === "FR") {
            fullAddress = zip
                ? `${address ? address + ", " : ""}${zip ? zip + " " : ""}${city ? city + ", " : ""}${
                      region ? `${region}` + ", " : ""
                  }${countryCode ? t(`COUNTRY.${countryCode}`) : ""}`
                : `${address ? address + ", " : ""} ${city ? city + ", " : ""}${region ? `${region}` + ", " : ""}${
                      countryCode ? t(`COUNTRY.${countryCode}`) : ""
                  }`
        } else if (countryCode === "CA" || countryCode === "US") {
            if (zip) {
                fullAddress = `${address ? address + ", " : ""}${city ? city + ", " : ""}${
                    region ? `${region}` + (zip ? " " : ", ") : ""
                }${zip ? zip + ", " : ""}${countryCode ? t(`COUNTRY.${countryCode}`) : ""}`
            } else {
                fullAddress = `${address ? address + ", " : ""}${city ? city + ", " : ""}${
                    region ? `${region}` + ", " : ""
                }${countryCode ? t(`COUNTRY.${countryCode}`) : ""}`
            }
        }

        return fullAddress.trim().replace(/^, */, "").replace(/, *$/, "")
    }

    // const internationalPhone = (phone: string, countryCode: CountryCode): string => {
    //     try {
    //         const formated = parsePhoneNumber(phone, countryCode)
    //         return formated.number
    //             ? countryCode === "CA" || countryCode === "US"
    //                 ? `+${formated.countryCallingCode} ${formated.nationalNumber
    //                       .toString()
    //                       .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}` // les numéros de téléphones US et CA ont des - à la place des espaces après le préfix
    //                 : formated.formatInternational()
    //             : ""
    //     } catch (error) {
    //         return ""
    //     }
    // }

    /**
     * @param {string} url - L'url à nettoyer
     * @returns {string} L'url néttoyer
     * **/
    const cleanUrl = (url: string): string => {
        return url.replace(/^(https?:\/\/)?/i, "").replace(/\/$/, "")
    }

    const queryStringToObject = (queryString: string) => {
        const params = new URLSearchParams(queryString)
        const obj: any = {}

        for (const [key, value] of params.entries()) {
            obj[key] = value
        }

        return obj
    }

    return {
        parseAddress,
        cleanUrl,
        queryStringToObject,
    }
}

export default useFormater
