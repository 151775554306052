import Cookies from "js-cookie"
import { create } from "zustand"

export interface IUiStore {
    title: string
    favicon: string
    preferredLanguage: string
}

export interface IUiSetter {
    updateState: (update: Partial<IUiStore>) => void
}

const reseller_infos = Cookies.get("reseller_infos") ? JSON.parse(Cookies.get("reseller_infos")) : {}

const initalState = {
    title: "",
    favicon: reseller_infos?.favicon || "",
    preferredLanguage: "",
}

const UiStore = create<IUiStore & IUiSetter>((set) => ({
    ...initalState,
    updateState: (update) => set(() => ({ ...update })),
}))

export default UiStore
