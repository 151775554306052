import { Button, CircularProgress, Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import Icon from "../Icon"
import useStyles from "./styles"

interface FormActionProps {
    handleCancel: () => void
    handleSubmit: () => void
    isFacebook?: boolean
    isDisabled?: boolean
    isLoading?: boolean
    isUpdating?: boolean
    buttonText?: string
}

const FormAction = ({
    handleCancel,
    handleSubmit,
    isDisabled,
    isLoading,
    isUpdating,
    buttonText,
    isFacebook = false,
}: FormActionProps) => {
    const { t } = useTranslation()
    const { classes } = useStyles()

    return (
        <Paper className={classes.wrapper} elevation={6}>
            <Button color="secondary" onClick={handleCancel}>
                {t("USERS.CANCEL")}
            </Button>
            <Button
                variant={"contained"}
                data-testid="create"
                type="submit"
                disabled={isDisabled}
                onClick={handleSubmit}
            >
                {isLoading && <CircularProgress size={16} sx={{ marginRight: "8px" }} />}
                {!isLoading && <Icon name={isUpdating ? "save" : "plus"} sx={{ marginRight: "8px" }} />}
                {buttonText ?? isFacebook
                    ? t("BUSINESSES.FACEBOOK_AUTH.CONNECT")
                    : isUpdating
                    ? t("USERS.SAVE")
                    : t("USERS.CREATE.SAVE")}
            </Button>
        </Paper>
    )
}

export default FormAction
