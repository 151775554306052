import env from "@/config/env"
import Cookies from "js-cookie"
import { useCallback } from "react"
import useUtilsService from "./useBase64"
interface FacebookInput {
    companyId: string
    user_id: string
    location_id: string
    cb: string
}
interface GoogleInput {
    companyId: string
    user_id: string
    cb: string
}
const useSelectAccount = () => {
    const { encodeBase64 } = useUtilsService()

    const objectToQueryString = (obj) => {
        const params = new URLSearchParams()
        for (const key in obj) {
            if (Object.hasOwnProperty.call(obj, key)) {
                params.append(key, obj[key])
            }
        }
        return params.toString()
    }

    const clearCache = () => {
        Cookies.remove("google_metadata")
        Cookies.remove("facebook_metadata")
        Cookies.remove("gmb_access_id")
    }

    const openGoogleAccount = useCallback(
        ({ companyId, user_id, cb }: GoogleInput) => {
            const b64 = encodeBase64(`companyId=${companyId}&user_id=${user_id}&cb=${cb}&auth_type=google`)
            const query = objectToQueryString({
                client_id: env.GMB_CLIENT_ID,
                redirect_uri: `${env.APP_OAUTH_REDIRECT_URL}`,
                response_type: "code",
                scope: env.SCOPE_GOOGLE_BUSINESS,
                prompt: "consent",
                include_granted_scopes: true,
                enable_granular_consent: true,
                access_type: "offline",
                gsiwebsdk: "3",
                flowName: "GeneralOAuthFlow",
                state: `${b64}`, // Pas besoin de mettre la clé
            })
            clearCache()
            window.location.href = "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?" + query
        },
        [encodeBase64]
    )

    const openFacebookAccount = useCallback(
        ({ companyId, user_id, location_id, cb }: FacebookInput) => {
            const b64 = encodeBase64(
                `companyId=${companyId}&user_id=${user_id}&location_id=${location_id}&cb=${cb}&auth_type=facebook`
            )
            const query = objectToQueryString({
                client_id: env.FACEBOOK_APP_ID,
                redirect_uri: `${env.APP_OAUTH_REDIRECT_URL}`,
                response_type: "token",
                scope: env.FACEBOOK_SCOPE,
                state: `${b64}`,
            })
            clearCache()
            window.location.href = "https://www.facebook.com/v18.0/dialog/oauth?" + query
        },
        [encodeBase64]
    )

    return {
        openGoogleAccount,
        openFacebookAccount,
    }
}

export default useSelectAccount
