import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { Box, Button, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import useStyles from "./styles"

interface TitleWrapperProps {
    handlePreviousButton: () => void
    title: string
    tooltipText?: string
    type?: string
}

const TitleWrapper = ({ handlePreviousButton, title, tooltipText, type = "google" }: TitleWrapperProps) => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    return (
        <Box className={classes.titleWrapper}>
            <CustomTooltip title={tooltipText || t("USERS.BACK_TOOLTIP")}>
                <Button onClick={handlePreviousButton}>
                    <Icon name="back" />
                </Button>
            </CustomTooltip>
            <Box>
                <Typography data-testid="title" variant="h1">
                    {title}
                </Typography>
                <Typography className="subtitle">
                    {t(type === "facebook" ? "BUSINESSES.FACEBOOK_AUTH.SUBTITLE" : "BUSINESSES.OAUTH.SUBTITLE")}
                </Typography>
            </Box>
        </Box>
    )
}

export default TitleWrapper
