// import { CategoryType } from "./../location/index"
import Cookies from "js-cookie"
import { create } from "zustand"

interface CategoryTypeFacebook {
    id: string
    name: string
}

export interface PageType {
    id: string
    name: string
    category_list: CategoryTypeFacebook
    is_connected?: boolean
}

export interface IFacebookStore {
    companyId: string
    callbackUrl: string
    pages: PageType[]
    locationId: string
}

export interface IFacebookSetter {
    update: (update: Partial<IFacebookStore>) => void
}

const initalState = {
    companyId: Cookies.get("facebook_company_id") || "",
    callbackUrl: Cookies.get("cb") || "",
    pages: [],
    locationId: Cookies.get("facebook_location_id") || "",
}

const FacebookStore = create<IFacebookStore & IFacebookSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
}))

export default FacebookStore
