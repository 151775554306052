import { makeStyles } from "tss-react/mui"
const useStyles = makeStyles()((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        height: "100vh",
        "& h1": {
            fontSize: "24px",
            fontFamily: "Poppins Medium",
            textAlign: "center",
            fontWeight: 300,
            lineHeight: 1.2,
            textTransform: "initial",
            color: "rgb(0, 4, 31)",
            display: "block",
            width: "100vw",
        },
    },
}))
export default useStyles
