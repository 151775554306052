import { ResellerApi } from "@/api"
import { UiService } from "@/services"
import ResellerStore, { IResellerSetter, IResellerStore } from "@/store/reseller"
import Cookies from "js-cookie"

class ResellerService {
    getState = (): IResellerStore & IResellerSetter => {
        return ResellerStore.getState()
    }

    setState = (update: Partial<IResellerStore>): void => {
        const { updateState } = this.getState()
        updateState(update)
    }

    getResellerInfos = async (domainUser) => {
        const reseller = await ResellerApi.fetchResellerInfos(domainUser || window.location.hostname)
        if (reseller) {
            Cookies.set(
                "reseller_infos",
                JSON.stringify({
                    logo: reseller?.vendor?.logo || "",
                    favicon: reseller?.vendor?.favicon || "",
                    name: reseller?.vendor?.name || "",
                })
            )
        }
        // Cookies.set("resellerLogo", reseller?.vendor?.logo || "")
        // Cookies.set("resellerFavicon", reseller?.vendor?.favicon || "")
        // Cookies.set("resellerName", reseller?.vendor?.name || "")

        UiService.setState({ favicon: reseller?.vendor?.favicon })

        return {
            resellerLogo: reseller?.vendor?.logo,
            resellerFavicon: reseller?.vendor?.favicon,
            resellerName: reseller?.vendor?.name,
        }
    }

    getResellerBusinesses = async ({
        page_number,
        page_size,
        user_uid = "",
    }: {
        page_number: number
        page_size: number
        user_uid: string
    }) => {
        const params = {
            page_number,
            page_size,
        }
        if (user_uid) {
            params["user_uid"] = user_uid
        }
        return ResellerApi.fetchResellerBusinesses(params)
    }

    clearReseller = () => {
        Cookies.remove("resellerFavicon")
        Cookies.remove("resellerLogo")
        Cookies.remove("resellerName")
    }
}

export default new ResellerService()
