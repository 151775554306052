import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const wrapperSx = { justifyContent: "center", alignItems: "center", height: "100%", px: { xs: 5.25, md: 18.75 } }
const h1Sx = { color: "primary.main", textAlign: "start", width: "100%", fontSize: 156 }
const oopsSx = {
    color: "action.active",
    textAlign: "start",
    width: "100%",
    fontSize: 34,
    fontFamily: "Poppins Medium",
}
const errorMessageSx = { color: "action.active", textAlign: "start", width: "100%", fontFamily: "Poppins Medium" }

const Error400 = () => {
    const { t } = useTranslation()

    return (
        <Stack sx={wrapperSx}>
            <Typography variant="h1" sx={h1Sx}>
                400
            </Typography>
            <Typography sx={oopsSx}>Oooops</Typography>
            <Typography sx={errorMessageSx} marginTop={3}>
                {t("ERROR_400")}
            </Typography>
        </Stack>
    )
}

export default Error400
