import { CountryCode } from "libphonenumber-js"
import { create } from "zustand"

export interface CategoriesType {
    primary?: string
    secondary?: string[]
}

export interface LocationCurrencyType {
    code?: string
    label?: string
}

export interface LocationDescriptionType {
    short?: string
}

export interface LocationPositionType {
    latitude?: string
    longitude?: string
}

export interface CategoryType {
    id?: string
    label?: string
    serviceTypes?: any
}

export interface LocationType {
    id?: string
    uid?: string
    name?: string
    additionalPhone?: string[]
    address?: string
    attributIds?: string[]
    categories?: CategoriesType
    city?: string
    companyId?: string
    country?: CountryCode
    createdAt?: string
    currency?: LocationCurrencyType
    description?: LocationDescriptionType
    gmbAccessId?: string
    language?: string
    latlng?: LocationPositionType
    locationState?: any
    logo?: string
    media?: string
    meta?: any
    newGmbReviewUrl?: string
    phone?: string
    placeId?: string
    pov?: string
    pubSubTopic?: any
    region?: string
    regularHours?: object[]
    serviceArea?: any
    smsPhoneNumber?: string
    social?: any
    specialHours?: any
    status?: string
    storeCode?: string
    subscriptionStatus?: string[]
    updatedAt?: string
    uuidGroup?: string
    uuidLocation?: string
    websiteUrl?: string
    countryPhone?: CountryCode
    zip?: string
}
export interface PrimaryCategoryType {
    id?: string
    label?: string
    language?: string
    name?: string
    serviceTypes?: any
}

interface ServiceType {
    meta: Meta
    locationId: string
    label: string
    title: string
    description: string
    language: string
    currency: string
    sections: ServiceSection[]
    createdAt: string
    id: string
    updatedAt?: any
}

export interface ServiceSection {
    label: string
    description?: string
    categoryId: string
    items: ServiceSectionItem[]
}

export interface ServiceSectionItem {
    label: string
    description?: string
    serviceTypeId?: string
    price?: string
    state?: string
    free?: boolean
    priceType?: string
}

interface Meta {
    gmb_id: string
}

export interface ILocationStore {
    location: LocationType
    companyName: string
    primaryCategory: PrimaryCategoryType
    secondaryCategory: CategoryType[]
    authUrl: string
    websiteUrl: string
    formatedAddress: string
    phone: string
    needReconnexion: boolean
    currentLocationId: string
    isLoading: boolean
    services: ServiceType
}

export interface ILocationSetter {
    update: (update: Partial<ILocationStore>) => void
}

const initalState: ILocationStore = {
    currentLocationId: "",
    location: {},
    companyName: "",
    primaryCategory: {
        id: "",
        label: "",
        serviceTypes: [],
    },
    secondaryCategory: [],
    authUrl: "",
    websiteUrl: "",
    formatedAddress: "",
    phone: "",
    needReconnexion: false,
    isLoading: false,
    services: {} as ServiceType,
}

const LocationStore = create<ILocationStore & ILocationSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
}))

export default LocationStore
