import Cookies from "js-cookie"
import { ApiService } from "../"
interface PageSizeType {
    page_number: number
    page_size: number
    user_uid: string
}

interface LocationAttributeType {
    locationId: string
    company_id: string
    lang: string
}

class ResellerApi {
    fetchResellerInfos: any = async (hostname: string) => {
        return await ApiService.get(`/reseller_infos`, {
            hostname,
            reseller_uid: Cookies.get("resellerUid") || "",
        })
    }

    fetchResellerBusinesses: any = async ({ page_number, page_size, user_uid }: PageSizeType) => {
        return await ApiService.get(`/locations/company`, {
            page_number,
            page_size,
            user_uid,
            reseller_uid: Cookies.get("resellerUid") || "",
        })
    }
}

export default new ResellerApi()
