import logoDilypse from "@/assets/Logo-Dilypse.png"
import FormAction from "@/components/base/form-action"
import TitleWrapper from "@/components/base/title-wrapper"
import useStyles, { formControlSx, updateButtonSx } from "@/components/multi-business/styles"
import useLogic from "@/hooks/google/useLogic"
import useFormater from "@/hooks/useFormater"
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"

import Icon from "@/components/base/Icon"
import BaseInput from "@/components/base/input"
import BaseSelect from "@/components/base/select"
import ResellerStore from "@/store/reseller"
import { useMemo } from "react"

const AuthGoogle = ({ mockData = null }: { mockData?: any }) => {
    const { t } = useTranslation()
    const { classes } = useStyles()

    const {
        accounts,
        locations,
        isFetching,
        countBusinesses,
        selectedBusinesses,
        isSync,
        account_id,
        filterInput,
        refreshData,
        handleInputChange,
        handleSelectChange,
        handleToggleCheckbox,
        handleSync,
        handleCancel,
        handlePrevious,
    } = useLogic()
    const { resellerLogo, resellerName } = ResellerStore()
    const { parseAddress } = useFormater()
    const locationsList = useMemo(() => {
        return mockData?.locations ? mockData?.locations : locations
    }, [locations, mockData])
    const accountsList = useMemo(() => {
        return mockData?.accounts ? mockData?.accounts : accounts
    }, [accounts, mockData])

    return (
        <Box className={classes.container}>
            <Box className="logo-wrapper">
                <img src={resellerLogo || logoDilypse} alt={resellerName || ""} />
            </Box>
            <Box className="wrapper">
                <Stack direction={{ sm: "row" }} gap={2} justifyContent={"space-between"}>
                    <TitleWrapper handlePreviousButton={handlePrevious} title={t("BUSINESSES.OAUTH.TITLE")} />
                    <Button variant="text" sx={updateButtonSx} onClick={refreshData}>
                        <Icon name="refresh" />
                        {t("UPDATE")}
                    </Button>
                </Stack>
                <Box className="list-wrapper">
                    <form
                        className="filter-inputs"
                        data-e2e="google-filter"
                        onSubmit={(e) => {
                            e.preventDefault()
                            refreshData()
                        }}
                    >
                        <BaseSelect
                            value={account_id}
                            dataTestId="accounts"
                            disabled={isFetching || isSync}
                            options={
                                accountsList?.length
                                    ? [
                                          ...accountsList.map((account) => ({
                                              label: account.accountName || "",
                                              value: account.name || "",
                                          })),
                                      ]
                                    : [{ value: "", label: t("BUSINESSES.OAUTH.NOT_GROUPED") }]
                            }
                            label={t("BUSINESSES.OAUTH.SELECT_GROUP")}
                            formControlSx={formControlSx}
                            onItemClicked={(e) => handleSelectChange(e)}
                        />
                        <BaseInput
                            label={t("BUSINESSES.OAUTH.SEARCH")}
                            data-testid="search"
                            disabled={isFetching || isSync}
                            inputRef={filterInput}
                            onInput={(event) => handleInputChange(event)}
                            endAdornment={
                                <IconButton type="submit" onClick={refreshData}>
                                    <Icon name={"search"} />
                                </IconButton>
                            }
                        />
                    </form>

                    {locations.length === 100 && (
                        <Typography className="search-limit" component="span">
                            {t("BUSINESSES.OAUTH.LIMIT")}
                        </Typography>
                    )}
                    {isFetching && (
                        <Box className="list-wrapper" sx={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress />
                        </Box>
                    )}
                    {!isFetching && (
                        <List data-testid="locations-list" data-e2e="locations-list">
                            {locationsList?.map((value) => (
                                <ListItemButton
                                    key={value.name}
                                    onClick={() => handleToggleCheckbox(value.name)}
                                    className={selectedBusinesses.includes(value.name) ? "selected" : ""}
                                    disabled={value.is_connected}
                                >
                                    <ListItemIcon>
                                        <Checkbox edge="start" checked={selectedBusinesses.includes(value.name)} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={value.locationName}
                                        secondary={
                                            value.address?.regionCode
                                                ? parseAddress({
                                                      region: value.address?.administrativeArea || "",
                                                      countryCode: value.address?.regionCode,
                                                      address: value.address?.addressLines?.length
                                                          ? value.address?.addressLines[0]
                                                          : "",
                                                      city: value.address?.locality || "",
                                                      zip: value.address?.postalCode || "",
                                                  })
                                                : ""
                                        }
                                    />
                                    {value.is_connected && (
                                        <ListItemIcon>
                                            <Icon name="link" />
                                        </ListItemIcon>
                                    )}
                                </ListItemButton>
                            ))}
                        </List>
                    )}
                </Box>

                {!isSync && (
                    <Typography component="span" className="selected-length">
                        {selectedBusinesses.length} {t("BUSINESSES.OAUTH.SELECTED_LENGTH")}
                    </Typography>
                )}
                {isSync && (
                    <Typography component="span" className="selected-length">
                        {countBusinesses} {t("BUSINESSES.OAUTH.of")} {selectedBusinesses.length}{" "}
                        {t("BUSINESSES.OAUTH.CONNECTED_LENGTH")}
                    </Typography>
                )}
                <FormAction
                    handleSubmit={handleSync}
                    handleCancel={handleCancel}
                    isDisabled={isFetching || isSync || !selectedBusinesses.length}
                    isLoading={isSync}
                />
            </Box>
        </Box>
    )
}

export default AuthGoogle
