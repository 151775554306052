import Cookies from "js-cookie"
import { create } from "zustand"

export interface IUserProfile {
    email: string
    firstname: string
    lastname: string
    uid: string
}

export interface IUserSessionStore {
    isLoggedIn: boolean
    lang?: string
    token: string
    profile: Partial<IUserProfile>
    isConnected: boolean
}

export interface IUserSessionSetter {
    setIsLoggedIn: (value: boolean) => void
    updateState: (value: Partial<IUserSessionStore>) => void
    updateProfile: (value: Partial<IUserProfile>) => void
    flush: () => void
}

const initalState = {
    isLoggedIn: !!Cookies.get("token"),
    token: Cookies.get("token") || "",
    lang: Cookies.get("i18next"),
    error: "",
    loading: false,
    profile: {
        email: Cookies.get("email"),
        firstname: Cookies.get("firstname"),
        lastname: Cookies.get("lastname"),
        uid: Cookies.get("user_uid"),
    },
    isConnected: true,
}

const UserSessionStore = create<IUserSessionStore & IUserSessionSetter>((set) => ({
    ...initalState,
    setIsLoggedIn: (value: boolean) => set({ isLoggedIn: value }),
    updateState: (newValue: any = {}) => set(() => ({ ...newValue })),
    updateProfile: (newValue: any = {}) =>
        set((state) => {
            const updated: any = { profile: { ...state.profile, ...newValue } }
            Cookies.set("firstname", updated.firstname)
            Cookies.set("lastname", updated.lastname)
            return updated
        }),
    flush: () =>
        set({
            isLoggedIn: false,
            profile: {},
            token: "",
            lang: "",
        }),
}))

export default UserSessionStore
