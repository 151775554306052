import Cookies from "js-cookie"
import { create } from "zustand"

export interface GoogleAccountsType {
    accountName?: string
    name?: string
    type?: string
}

export interface LocationAddressType {
    regionCode?: string
    postalCode?: string
    languageCode?: string
    administrativeArea?: string
    addressLines?: string[]
    locality?: string
}
export interface LocationType {
    name: string
    locationName: string
    is_connected: boolean
    address?: LocationAddressType
}
export interface IGoogleStore {
    gmb_access_id?: string
    // refreshToken: string
    companyId: string
    user_id: string
    accounts: GoogleAccountsType[]
    locations: LocationType[]
    callbackUrl: string
    locationName?: string
    code?: string
}

export interface IGoogleSetter {
    update: (update: Partial<IGoogleStore>) => void
}

const google_metadata = Cookies.get("google_metadata") ? JSON.parse(Cookies.get("google_metadata")) : {}
const initalState = {
    gmb_access_id: Cookies.get("gmb_access_id") || "",
    // refreshToken: Cookies.get("refreshToken") || "",
    companyId: google_metadata?.companyId || "",
    user_id: google_metadata?.user_id || "",
    callbackUrl: google_metadata?.cb || "",
    locationName: "",
    code: "",
    accounts: [],
    locations: [],
}

const GoogleStore = create<IGoogleStore & IGoogleSetter>((set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
}))

export default GoogleStore
