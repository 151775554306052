import { Alert, IconButton, Snackbar } from "@mui/material"
import Icon from "@/components/base/Icon"
import CustomTooltip from "@/components/base/Tooltip"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import NotificationStore from "@/store/notification"

interface SnackbarSuccess {
    autoHideDuration?: number
}

const SnackbarSuccess = ({ autoHideDuration = 10000 }: SnackbarSuccess) => {
    const { message, show, update } = NotificationStore()
    const handleOnClose = useCallback(() => {
        update({ message: "" })
    }, [update])
    const { t } = useTranslation()

    return (
        <Snackbar
            open={show}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            autoHideDuration={autoHideDuration}
            onClose={handleOnClose}
        >
            <Alert
                icon={<Icon name="check" />}
                sx={{
                    backgroundColor: "success.main",
                }}
                action={
                    <CustomTooltip title={t("USERS.CLOSE")}>
                        <IconButton sx={{ color: "white" }} onClick={() => handleOnClose()}>
                            <Icon name="x" />
                        </IconButton>
                    </CustomTooltip>
                }
                severity="success"
            >
                {message}
            </Alert>
        </Snackbar>
    )
}

export default SnackbarSuccess
