import { useMemo } from "react"
import lightTheme from "./light"
import { createTheme } from "@mui/material"

const DefaultTheme = ({ mode }: { mode: "light" | "dark" }) => {
    const theme = useMemo(
        () =>
            createTheme({
                shape: {
                    borderRadius: 8,
                },
                palette: {
                    mode,
                    ...(mode === "light" && lightTheme.palette),
                },
                typography: {
                    fontFamily: "Poppins",
                    allVariants: {
                        textTransform: "initial",
                    },
                    h1: {
                        fontFamily: "Poppins Medium",
                    },
                    h2: {
                        fontFamily: "Poppins Medium",
                    },
                    h3: {
                        fontFamily: "Poppins Medium",
                    },
                    h4: {
                        fontFamily: "Poppins Medium",
                    },
                    h5: {
                        fontFamily: "Poppins Medium",
                    },
                    h6: {
                        fontFamily: "Poppins Medium",
                    },
                },
                ...(mode === "light" && { components: lightTheme.components }),
            }),
        [mode]
    )

    return theme
}

export default DefaultTheme
