import { Buffer } from "buffer"
import useFormater from "./useFormater"
const useUtilsService = () => {
    const { queryStringToObject } = useFormater()
    const decode64AndParse = (base64: string) => {
        return queryStringToObject(Buffer.from(base64, "base64").toString("ascii"))
    }

    const encodeBase64 = (base64: string) => {
        return Buffer.from(base64).toString("base64")
    }

    return {
        decode64AndParse,
        encodeBase64,
    }
}

export default useUtilsService
