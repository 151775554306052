import { DispatchrService } from ".."
export interface CreateFBAccessType {
    company_id: string
    access_token: string
    expires_in: number
    location_id: string
}

interface ApiFacebookData {
    company_id: string
    location_id: string
    pageId?: string
}

class FacebookApi {
    createFacebookAccess: any = async (data: CreateFBAccessType) => {
        return await DispatchrService.post(`_entity/fb_access`, data)
    }

    fetchPages: any = async ({ company_id, location_id }: ApiFacebookData) => {
        return await DispatchrService.get(`/fb/pages?companyId=${company_id}&locationId=${location_id}`)
    }

    synchronize: any = async ({ company_id, location_id, pageId }: ApiFacebookData) => {
        return await DispatchrService.post(`/fb/select-page?companyId=${company_id}&locationId=${location_id}`, {
            pageId,
        })
    }
}

export default new FacebookApi()
