import { Theme } from "@emotion/react"
import { SxProps } from "@mui/material"

export const withSxProp = (sxProp: SxProps<Theme>, other: SxProps<Theme> | undefined): SxProps<Theme> => {
    if (!other) {
        return sxProp
    } else {
        return mergeSx(sxProp, other)
    }
}

export const mergeSx = (...sxProps: SxProps<Theme>[]): SxProps<Theme> => {
    return sxProps.reduce((prev, currentValue) => {
        return [
            ...(Array.isArray(prev) ? prev : [prev]),
            ...(Array.isArray(currentValue) ? currentValue : [currentValue]),
        ]
    }, [] as SxProps<Theme>)
}

export const removeHashUrl = () => {
    const uri = window.location.toString()

    if (uri.indexOf("#") > 0) {
        const clean_uri = uri.substring(0, uri.indexOf("#"))

        window.history.replaceState({}, document.title, clean_uri)
    }
}
