import useLogic from "@/hooks/redirect/useLogic"
import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import useStyles from "./style"
const Redirect = () => {
    const { locationName } = useLogic()
    const { t } = useTranslation()
    const { classes } = useStyles()
    return (
        <Box className={classes.container}>
            <Box>
                <Typography data-testid="title" variant="h1">
                    {t("REDIRECTION")}
                </Typography>
            </Box>
        </Box>
    )
}

export default Redirect
