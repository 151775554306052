import { LocationApi } from "@/api"
import { CreateGMBAccessType } from "@/api/location"
import env from "@/config/env"
import { socket } from "@/config/socket"
import useFormater from "@/hooks/useFormater"
import { ResellerService } from "@/services"
import GoogleStore from "@/store/google"
import Cookies from "js-cookie"
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import useUtilsService from "../useBase64"
import useNotification from "../useNotification"
import useSelectAccount from "../useSelectAccount"

const useLogic = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const { companyId, gmb_access_id, accounts, locations, callbackUrl, user_id, update } = GoogleStore()
    const [isFetching, setIsFetching] = useState(false)
    const [canFetch, setCanFetch] = useState(false)
    const [filter, setFilter] = useState("")
    const [accountId, setAccountId] = useState("")
    const [selectedBusinesses, setSelectedBusinesses] = useState<string[]>([])
    const [countBusinesses, setCountBusinesses] = useState<number>(0)
    const [isSync, setIsSync] = useState<boolean>(false)
    const { parseAddress } = useFormater()
    const { decode64AndParse } = useUtilsService()
    const { notif } = useNotification()
    const { t } = useTranslation()
    const filterInput = useRef(null)
    const { openGoogleAccount } = useSelectAccount()

    const createGmbAccess = useCallback(async (data: CreateGMBAccessType) => {
        const body = data
        const resp = await LocationApi.createGmbAccess(body)
        if (!resp?.error) {
            setIsFetching(true)
            update({
                gmb_access_id: resp?.gmbAccessId,
                companyId: data.companyId,
            })
            resp.gmbAccessId && Cookies.set("gmb_access_id", resp?.gmbAccessId)
            setIsFetching(false)
        } else {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        }
    }, [])

    const fetchAccounts = useCallback(async (data: { companyId: string; gmbAccessId: string }) => {
        setIsFetching(true)
        try {
            const resp: any = await LocationApi.fetchAccounts(data)
            if (!resp.error) {
                if (resp?.length) {
                    let element = resp.find((obj: any) => obj?.type === "ORGANIZATION")
                    if (!element) {
                        element = resp.find((obj: any) => {
                            return obj?.type === "LOCATION_GROUP"
                        })
                    }
                    if (!element) {
                        element = resp.find((obj: any) => obj.type === "PERSONAL")
                    }
                    if (element?.name) {
                        setAccountId(element?.name)
                    }
                    if (element?.length > 0) {
                        setAccountId(element[0]?.name)
                    }
                }
                update({
                    accounts: resp,
                })
            } else {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
        } catch (err) {
            notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
        }

        setIsFetching(false)
    }, [])

    const fetchLocations = useCallback(
        async (data: { companyId: string; filtre: string; gmb_access_id: string; account_id?: string }) => {
            setIsFetching(true)
            const resp = await LocationApi.fetchLocations(data)
            if (!resp?.error) {
                setSelectedBusinesses([])
                update({
                    locations: resp || [],
                })
                setTimeout(() => {
                    filterInput.current.focus()
                }, 300)
            } else {
                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
            setIsFetching(false)
        },
        [filterInput]
    )

    const handleInputChange = (data: ChangeEvent<HTMLInputElement>) => {
        setFilter(data.target.value)
    }

    const handleSelectChange = useCallback(
        async (data: any) => {
            const account = data.value !== "not-grouped" ? data.value : ""
            setAccountId(account)
            setSelectedBusinesses(() => [])
            fetchLocations({
                companyId,
                gmb_access_id,
                filtre: filter,
                account_id: account,
            })
        },
        [companyId, gmb_access_id, filter, fetchLocations, setAccountId, setSelectedBusinesses]
    )

    const handleToggleCheckbox = useCallback(
        (value: string) => {
            if (selectedBusinesses.indexOf(value) > -1) {
                setSelectedBusinesses((previousSelected) => previousSelected.filter((item) => item !== value))
            } else {
                setSelectedBusinesses((previousSelected) => [...previousSelected, value])
            }
        },
        [selectedBusinesses, setSelectedBusinesses]
    )

    const handleReturn = (created = 0) => {
        Cookies.remove("google_metadata")
        Cookies.remove("facebook_metadata")
        Cookies.remove("gmb_access_id")
        ResellerService.clearReseller()
        window.location.href = `${callbackUrl}${created ? "/?auth_type=google&created=" + created : ""}`
    }

    const handleSync = useCallback(async () => {
        setIsSync(true)
        socket.emit("connectLocation", {
            companyId: companyId,
            gmbId: gmb_access_id,
            userId: user_id,
        })

        socket.on("locationStatus", (res) => {
            setCountBusinesses(res.nbPassed)
            if (res.nbPassed === res.totalCount) {
                handleReturn(selectedBusinesses.length)
                // setIsSync(false)
            }
        })
        await LocationApi.synchronize({
            company_id: companyId,
            gmb_access_id,
            locations_name: selectedBusinesses,
            user_id,
            account_id: accountId,
        })
    }, [selectedBusinesses, companyId, gmb_access_id, user_id, accountId])

    const handleCancel = () => {
        handleReturn()
    }

    const handlePrevious = useCallback(() => {
        openGoogleAccount({
            companyId,
            user_id,
            cb: callbackUrl,
        })
    }, [companyId, user_id, callbackUrl])

    const refreshData = useCallback(() => {
        fetchLocations({
            companyId,
            gmb_access_id,
            filtre: filter,
            account_id: accountId,
        })
    }, [gmb_access_id, companyId, accountId, filter])

    useEffect(() => {
        const code = searchParams.get("code") || ""
        const state = searchParams.get("state") || ""
        let company_id = ""
        let inf = null
        if (state) {
            inf = decode64AndParse(state)
            company_id = inf?.companyId ?? ""
            Cookies.set("google_metadata", JSON.stringify(inf))

            update({
                companyId: inf?.companyId ?? "",
                user_id: inf?.user_id ?? "",
                callbackUrl: inf?.cb ?? "",
                gmb_access_id: inf?.gmb_access_id ?? "",
            })
            if (inf?.gmb_access_id) {
                Cookies.set("gmb_access_id", inf?.gmb_access_id)
            } else {
                Cookies.remove("gmb_access_id")
            }
        }
        // suppression du query token et scope dans l'url

        for (const key of searchParams.keys()) {
            searchParams.delete(key)
        }
        setSearchParams(searchParams)
        if (code && company_id) {
            if (!gmb_access_id) {
                createGmbAccess({
                    code,
                    companyId: company_id,
                    redirecturi: env.APP_OAUTH_REDIRECT_URL,
                })
            }
        }
    }, [searchParams])

    useEffect(() => {
        if (gmb_access_id && companyId) {
            setCanFetch(true)
            fetchAccounts({ companyId, gmbAccessId: gmb_access_id })
        }
    }, [searchParams, gmb_access_id, companyId])

    useEffect(() => {
        if (!Cookies.get("google_metadata") && !searchParams.get("code")) {
            navigate("/error")
        }
    }, [searchParams, navigate])

    useEffect(() => {
        if (canFetch && gmb_access_id && companyId && accountId) {
            fetchLocations({
                companyId,
                gmb_access_id,
                filtre: filter,
                account_id: accountId,
            })
        }
    }, [canFetch, gmb_access_id, companyId, accountId])

    return {
        accounts,
        locations,
        isFetching,
        countBusinesses,
        selectedBusinesses,
        isSync,
        account_id: accountId,
        filterInput,
        handleInputChange,
        handleSelectChange,
        handleSync,
        handleCancel,
        handleToggleCheckbox,
        parseAddress,
        refreshData,
        handlePrevious,
    }
}

export default useLogic
