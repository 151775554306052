import { ThemeOptions } from "@mui/material"

const lightTheme: ThemeOptions = {
    palette: {
        primary: {
            main: "#0180FF",
            dark: "#0059B2",
        },
        secondary: {
            main: "rgba(1, 128, 255, 0.12)",
            light: "rgba(33, 150, 243, 0.08)",
        },
        success: {
            main: "#03CC6C",
        },
        error: {
            main: "#F46259",
        },
        warning: {
            main: "#FFC431",
        },
        action: {
            active: "#68738D",
            disabled: "rgba(104, 115, 141, 0.42)",
        },
        info: {
            main: "#2EAFF7",
        },
        background: {
            default: "rgb(244, 245, 250)",
        },
        divider: "rgba(91, 125, 216, 0.16)",
        text: {
            disabled: "rgba(104, 115, 141, 0.64)",
            primary: "#00041F",
            secondary: "#808CA9",
        },
        common: {
            white: "rgb(255, 255, 255)",
        },
    },
    components: {
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(53, 56, 75, 0.42)",
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.primary": {
                        color: "#0180FF",
                    },
                    "&.success": {
                        color: "#03CC6C",
                    },
                    "&.error": {
                        color: "#F46259",
                    },
                    "&.MuiTypography-h1": {
                        fontWeight: 500,
                    },
                    "&.MuiTypography-h3": {
                        fontSize: "20px",
                        fontWeight: 500,
                        lineHeight: "32px",
                        letterSpacing: "0.15000000596046448px",
                    },
                    color: "#00041F",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    padding: 16,
                    boxShadow: "0px 1px 1px 0px rgba(0, 4, 31, 0.08), 0px 1px 4px 0px rgba(8, 0, 31, 0.12)",
                },
                elevation6: {
                    boxShadow: "0px 6px 10px 0px rgba(0, 4, 31, 0.16), 0px 1px 18px 0px rgba(0, 4, 31, 0.16)",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 2px 2px 0px rgba(0, 4, 31, 0.08), 0px 1px 5px 0px rgba(0, 4, 31, 0.08)",
                    "&:disabled": {
                        color: "rgba(104, 115, 141, 0.42) !important",
                        backgroundColor: "rgba(104, 115, 141, 0.32) !important",
                        "& *": {
                            color: "rgba(104, 115, 141, 0.42) !important",
                        },
                    },
                    "&.MuiButton-containedSuccess *": {
                        color: "white",
                    },
                },
                containedPrimary: { backgroundColor: "#0180FF", "& *": { color: "white" } },
                textSecondary: {
                    backgroundColor: "rgba(1, 128, 255, 0.12)",
                    color: "#0180FF",
                    border: "1px solid transparent",
                    boxShadow: "none",
                    "&:hover": {
                        backgroundColor: "rgba(1, 128, 255, 0.12)",
                        border: "1px solid rgba(33, 150, 243, 0.50)",
                    },
                },
                textPrimary: {
                    boxShadow: "none",
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled input": {
                        WebkitTextFillColor: "rgba(104, 115, 141, 0.64)",
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: "1px solid #E4EAFA",
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                colorPrimary: {
                    "& svg": {
                        color: "#68738D",
                    },
                    "&.Mui-checked svg": {
                        color: "#0180FF",
                    },
                },
            },
        },
        MuiFormGroup: {
            styleOverrides: {
                root: {
                    "& .MuiFormControlLabel-root": {
                        margin: 0,
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                outlinedSecondary: {
                    border: "1px solid rgba(91, 125, 216, 0.16)",
                    padding: 4,
                    "& svg": {
                        color: "#0180FF",
                    },
                    "& .MuiChip-label": {
                        padding: "3px 6px",
                        color: "#00041F",
                        fontSize: 13,
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(172, 182, 229, 0.16)",
                    "& input": {
                        "&::placeholder, &::-ms-input-placeholder": {
                            color: "#68738D",
                            opacity: 1,
                        },
                    },
                    "& fieldset": {
                        border: "none",
                        borderBottom: "1px solid #5B7DD81F",
                    },
                    "&:hover, &.Mui-focused": {
                        "& fieldset": {
                            borderBottom: "none",
                        },
                    },
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: "#fff",
                        boxShadow:
                            "0px 4px 5px 0px rgba(0, 4, 31, 0.08), 0px 1px 10px 0px rgba(0, 4, 31, 0.08) !important",
                    },
                    "&.Mui-disabled": {
                        color: "rgba(104, 115, 141, 0.64)",
                        backgroundColor: "rgba(104, 115, 141, 0.42)",
                    },
                },
            },
        },
    },
}

export default lightTheme
