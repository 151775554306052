import useUtilsService from "@/hooks/useBase64"
import useFormater from "@/hooks/useFormater"
import Cookies from "js-cookie"
import { useEffect, useMemo } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

const Home = () => {
    const { queryStringToObject } = useFormater()
    const { hash } = useLocation()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const { decode64AndParse } = useUtilsService()

    const hashParams = useMemo(() => {
        if (hash) {
            const data = queryStringToObject(hash.replace("#", ""))
            return data?.state
        }
        if (Cookies.get("facebook_metadata")) {
            return Cookies.get("facebook_metadata")
        }
        return null
    }, [hash, queryStringToObject])

    const queryParams = useMemo(() => {
        if (searchParams && searchParams.get("state")) {
            const data = decode64AndParse(searchParams.get("state"))
            return data
        }
        if (Cookies.get("google_metadata")) {
            return Cookies.get("google_metadata")
        }
        return null
    }, [searchParams])

    useEffect(() => {
        if (hashParams) {
            navigate(`/auth_facebook/${hash}`)
            return
        }
        if (queryParams) {
            if (queryParams?.locationName) {
                navigate(`/redirect?${searchParams}`)
            } else {
                navigate(`/auth_google?${searchParams}`)
            }
            return
        }
        navigate("/error")
    }, [hashParams, hash, queryParams, searchParams, navigate])

    return <></>
}

export default Home
