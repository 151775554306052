import createCache from "@emotion/cache"
import { CacheProvider, ThemeProvider } from "@emotion/react"
import { wrapCreateBrowserRouter } from "@sentry/react"
import { Suspense, createContext, useEffect, useMemo, useState } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import RoutesList from "./routes"
import UserSessionStore from "./store/user-session"
import DefaultTheme from "./theme"
const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter(RoutesList)

export const ThemeModeContext = createContext({ toggleMode: () => undefined })

const App = () => {
    const [mode, setMode] = useState<"light" | "dark">("light")
    const defaultTheme = DefaultTheme({ mode })
    const themeMode = useMemo(
        () => ({
            toggleMode: () => {
                setMode((prevMode) => (prevMode === "light" ? "dark" : "light"))
                return undefined
            },
        }),
        []
    )

    const cache = createCache({
        key: "mui",
        prepend: true,
    })

    const { updateState } = UserSessionStore.getState()

    useEffect(() => {
        window.addEventListener("offline", () => updateState({ isConnected: false }))
        window.addEventListener("online", () => updateState({ isConnected: true }))

        // cleanup if we unmount
        return () => {
            window.removeEventListener("offline", () => updateState({ isConnected: false }))
            window.removeEventListener("online", () => updateState({ isConnected: true }))
        }
    }, [updateState])

    return (
        <ThemeModeContext.Provider value={themeMode}>
            <CacheProvider value={cache}>
                <ThemeProvider theme={defaultTheme}>
                    <Suspense fallback="loading">
                        <RouterProvider router={router} />
                    </Suspense>
                </ThemeProvider>
            </CacheProvider>
        </ThemeModeContext.Provider>
    )
}

export default App
