import { SxProps } from "@mui/material"

export const defaultSx: SxProps = {
    borderRadius: 1,
    backgroundColor: "rgba(172, 182, 229, 0.16)",
    "&.Mui-disabled": {
        backgroundColor: "rgba(172, 182, 229, 0.16)",
        "&::before": {
            borderBottomStyle: "initial",
        },
    },
    "& .MuiFormLabel-root": {
        maxWidth: "calc(100% - 37px)",
    },
    "& fieldset": {
        border: "none",
    },
    "body &.MuiInputBase-root:hover": {
        "&::before": {
            borderBottom: "none",
        },
    },
    "&::before, &::after": {
        borderBottom: "none",
    },
    "body &.MuiInputBase-root .MuiSelect-select": {
        display: "flex",
        gap: 1,
        alignItems: "center",
    },
    "& .MuiSelect-select": {
        "& > span": {
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    },
}

export const menuSx = {
    paddingBottom: 1,
    paddingTop: 1,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexDirection: "row",
    gap: 1,
}
